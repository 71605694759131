<template>
  <div ref="os" data-test="us-overlay-scrollbar" class="os-host">
    <div class="os-resize-observer-host"></div>
    <div class="os-padding">
      <div class="os-viewport">
        <div class="os-content">
          <slot />
        </div>
      </div>
    </div>
    <div class="os-scrollbar os-scrollbar-horizontal">
      <div class="os-scrollbar-track">
        <div class="os-scrollbar-handle"></div>
      </div>
    </div>
    <div class="os-scrollbar os-scrollbar-vertical">
      <div class="os-scrollbar-track">
        <div class="os-scrollbar-handle"></div>
      </div>
    </div>
    <div class="os-scrollbar-corner"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue'
import OverlayScrollbars from 'overlayscrollbars'

export default defineComponent({
  name: 'USHorizontalScrollbarContainer',
  props: {
    enableVerticalScroll: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const os = ref<HTMLElement>()
    let osInstance: OverlayScrollbars | null = null

    onMounted(() => {
      if (os.value) {
        osInstance = OverlayScrollbars(os.value, {
          overflowBehavior: {
            y: props.enableVerticalScroll ? 'scroll' : 'hidden'
          },
          scrollbars: {
            visibility: 'hidden',
            dragScrolling: true,
            touchSupport: true,
            clickScrolling: true
          }
        })
      }
    })

    onBeforeUnmount(() => {
      if (osInstance) {
        osInstance.destroy()
      }
    })

    return { os }
  }
})
</script>

<style lang="scss">
@import 'overlayscrollbars/css/OverlayScrollbars.min.css';
</style>
